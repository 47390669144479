<template>
  <v-list-item dense style="border-top:1px solid #ddd" v-if="!deleted">
    <v-overlay absolute :value="deleting" opacity=".9" color="primary">
      <v-progress-circular
        indeterminate
        size="28"
        class="mr-2"
      ></v-progress-circular>
      Deleting...
    </v-overlay>
    <v-list-item-avatar>
      <v-btn color="error darken-1" text icon @click="delete_rule()"
        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
      >
    </v-list-item-avatar>
    <v-list-item-title>
      <div style="width:100%;font-size:1.2em;" class="mb-1">
        <div style="width:100%;padding-bottom:4px">
          {{ rule.fqdn.friendly_name }}
        </div>

        <v-dialog v-model="cert_dialog" width="800" class="pa-0">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" v-on="on" x-small
              >{{ certs.length }} Certificates
            </v-btn>
          </template>
          <v-card
            :style="`border-radius:0;border-left: 15px solid ${group.color}`"
          >
            <v-toolbar class="pb-4">
              <v-btn icon @click="cert_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ rule.fqdn.friendly_name }}</v-toolbar-title>
            </v-toolbar>
            <CertificateTable v-if="certs" :certificates="certs" />
          </v-card>
        </v-dialog>

        <v-btn color="primary" x-small class="ml-1">
          {{ rule.issued_uris.aia_uris.length }} AIA
        </v-btn>
        <v-btn color="primary" x-small class="ml-1">
          {{ rule.issued_uris.sia_uris.length }} SIA
        </v-btn>
        <v-btn color="primary" x-small class="ml-1">
          {{ rule.issued_uris.crl_uris.length }} CRL
        </v-btn>
        <v-btn color="primary" x-small class="ml-1">
          {{ rule.issued_uris.ocsp_uris.length }} OCSP
        </v-btn>
      </div>
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-else>
    DELETED
  </v-list-item>
</template>

<script>
import UserService from "../../../store/UserService";
import CertificateTable from "../../CertificateTable";

export default {
  name: "CaRule",
  components: { CertificateTable },
  props: ["rule", "token", "group"],
  data: function() {
    return {
      cert_dialog: false,
      deleting: false,
      deleted: false,
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null,
      certs: [],
      certs_loading: true,
      num_certs: null,
      cert_table_headers: [
        { text: "", sortable: false, width: "104", value: "id" },
        { text: "Issuer", sortable: true, value: "issuer_fqdn.friendly_name" },
        /*{text: 'Subject', sortable: true, value: 'subject_fqdn.friendly_name'},*/
        { text: "Valid To", sortable: true, width: 120, value: "valid_from" },
        { text: "Status", sortable: true, width: 120, value: "is_revoked" }
      ]
    };
  },
  computed: {},
  created: function() {
    this.get_name_certs();
  },
  methods: {
    get_name_certs: function() {
      this.certs_loading = true;
      let rule_component = this;
      let args = [
        "groups=true",
        "size=10000",
        "ca=true",
        "subject_sha1=" + this.rule.fqdn.sha1,
        "public_key_id=" + this.rule.public_key.id
      ];
      UserService.getLocalApi("certificates/?" + args.join("&")).then(
        response => {
          rule_component.certs = response.data.results;
          this.certs_loading = false;
          this.num_certs = response.data.count;
        }
      );
    },
    delete_rule: function() {
      let self = this;
      self.deleting = true;

      UserService.deleteLocalApi("monitoring-group-ca-rules/" + this.rule.id)
        .then(() => {
          this.$store.dispatch("loadUserSettings", true);
          self.deleted = true;
          self.deleting = false;
        })
        .catch(function(error) {
          self.deleting = false;
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
