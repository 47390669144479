<template>
  <v-card class="mb-2">
    <v-dialog v-model="color_dialog" width="300">
      <v-card>
        <v-card-title> {{ group.group.name }} Color </v-card-title>

        <v-card-text class="ma-0 pa-0">
          <v-color-picker
            v-model="color_picked"
            class="ma-2 mb-0 mt-0"
            hide-inputs
          ></v-color-picker>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="color_dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="
              group.settings.color = color_picked.hex;
              color_dialog = false;
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_dialog" width="500">
      <v-card v-if="group.group.group_type === 3" :loading="deleting">
        <v-card-title class="headline" primary-title>
          Stop monitoring
          <span style="color:#7192B0;font-weight:bolder;" class="pl-2"
            >[[ group_to_delete.group.name ]]</span
          >?
        </v-card-title>

        <v-card-text>
          This will not delete the application but it will remove your
          monitoring of it.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="group_to_delete = null">CANCEL</v-btn>
          <v-btn
            color="error darken-1"
            @click="remove_group_monitoring(group_to_delete)"
            >DELETE</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else :loading="deleting">
        <v-card-title class="headline" primary-title>
          Delete Group
          <span style="color:#7192B0;font-weight:bolder;" class="pl-2"
            >[[ group_to_delete.group.name ]]</span
          >?
        </v-card-title>

        <v-card-text>
          This action cannot be undone and will delete all monitoring rules and
          settings.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="group_to_delete = null">CANCEL</v-btn>
          <v-btn color="error darken-1" @click="delete_group(group_to_delete)"
            >DELETE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="new_rule_dialog" width="500">
      <NewPathRule :group="group" />
    </v-dialog>

    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-card-title
        @click.stop="expanded = !expanded"
        :style="`border-left: 15px solid ${group.settings.color};`"
      >
        <v-text-field
          hide-details
          dense
          v-model="group.group.name"
          outlined
          class="pl-0"
          solo
        >
          <template v-slot:prepend class="pa-0 ma-0">
            <GroupSettings :settings="group.settings"></GroupSettings>

            <v-card
              :style="
                `width:40px;height:40px;background-color:${group.settings.color};`
              "
              elevation="4"
              class="pa-0 ma-0 mt-n2"
              @click.stop="
                color_dialog = true;
                color_group = group;
              "
            ></v-card>
          </template>
        </v-text-field>
      </v-card-title>
      <v-card-text
        v-show="do_i_show"
        :style="`border-left: 15px solid ${group.settings.color};`"
        class="ma-0 mt-n1"
      >
        <v-row>
          <v-col cols="12" md="6">
            <template v-if="group.group.group_type === 1">
              <v-list two-line subheader dense>
                <v-subheader
                  >Certification Authorities
                  <v-spacer></v-spacer>
                  <v-btn small class="success" style="float:right;">
                    <v-icon small>mdi-plus</v-icon> Add CA
                  </v-btn></v-subheader
                >
                <CaRule
                  v-for="test in group.group.ca_rules"
                  :group="group.group"
                  :rule="test"
                  :key="test.id"
                ></CaRule>
              </v-list>
            </template>
            <template v-else-if="group.group.group_type === 2">
              <v-list two-line subheader dense>
                <v-subheader style="border-bottom:1px solid #ddd;">
                  Validation Paths<v-spacer></v-spacer>
                  <v-btn
                    small
                    class="success"
                    style="float:right;"
                    @click.stop="new_rule_dialog = true"
                  >
                    <v-icon small>mdi-plus</v-icon>
                    Add Root
                  </v-btn>
                </v-subheader>
                <PathRule
                  v-for="test in group.group.path_rules"
                  :group="group.group"
                  :rule="test"
                  :key="test.id"
                ></PathRule>
              </v-list>
            </template>
          </v-col>
          <v-col cols="12" md="6">
            <v-subheader>Notification Settings</v-subheader>

            <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item-avatar
                  ><v-icon color="green"
                    >mdi-bell-ring</v-icon
                  ></v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title>CA Certificates</v-list-item-title>
                  <v-list-item-subtitle
                    >These settings apply to the monitored
                    CAs</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                style="padding-left:50px;border-left:1px solid #ddd;margin-left:35px;"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.new_cas"
                      color="primary"
                      label="New"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.renewed_cas"
                      color="primary"
                      label="Renewed"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.rekeyed_cas"
                      color="primary"
                      label="Rekeyed"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.ca_revoked"
                      color="primary"
                      label="Revoked"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.ca_unrevoked"
                      color="primary"
                      label="Unrevoked"
                    ></v-checkbox>
                    <v-dialog width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          block
                          v-if="certExpiration(group.settings)"
                          small
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon small class="pr-2">mdi-wrench</v-icon>
                          Expiration</v-btn
                        >
                        <v-btn
                          block
                          v-else
                          small
                          color="#eee"
                          light
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon small class="pr-2">mdi-wrench</v-icon>
                          Expiration</v-btn
                        >
                      </template>

                      <v-card>
                        <v-card-title class="headline" primary-title>
                          Certificate Expiration Settings
                        </v-card-title>

                        <v-card-text>
                          <v-alert type="info" text>
                            You will receive expiration alerts only if a
                            certificate is still hosted <strong>or</strong> it's
                            the newest known version.
                          </v-alert>
                          <v-checkbox
                            hide-details
                            dense
                            v-model="group.settings.ca_expired"
                            color="primary"
                            label="Notify at expiration"
                          ></v-checkbox>
                          <v-text-field
                            v-model="group.settings.ca_percent_life"
                            label="Notify at"
                            suffix="percent life remaining"
                          ></v-text-field>
                          <v-text-field
                            v-model="group.settings.ca_hours_to_expiration"
                            label="Notify at"
                            suffix="hours to expiration"
                          ></v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>

            <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item-avatar
                  ><v-icon color="green"
                    >mdi-bell-ring</v-icon
                  ></v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title>Operations</v-list-item-title>
                  <v-list-item-subtitle
                    >These settings apply to the URLs issued by monitored
                    CAs</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                style="padding-left:50px;border-left:1px solid #ddd;margin-left:35px;"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="pa-2">
                      <v-dialog width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            block
                            v-if="group.settings.aia_minutes"
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            AIA</v-btn
                          >
                          <v-btn
                            block
                            v-else
                            small
                            color="#eee"
                            light
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            AIA</v-btn
                          >
                        </template>

                        <v-card>
                          <v-card-title class="headline" primary-title>
                            AIA Settings
                          </v-card-title>

                          <v-card-text>
                            <v-text-field
                              v-model="group.settings.aia_minutes"
                              label="Notify if unavailable for"
                              suffix="minutes"
                            ></v-text-field>
                            <v-text-field
                              v-model="group.settings.aia_renotify"
                              label="Notify again every"
                              suffix="minutes"
                            ></v-text-field>
                            <v-checkbox
                              hide-details
                              dense
                              v-model="group.settings.aia_resolved"
                              color="primary"
                              label="Notify when resolved"
                            ></v-checkbox>
                          </v-card-text>
                        </v-card>
                      </v-dialog>

                      <v-dialog width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-2"
                            block
                            v-if="group.settings.sia_minutes"
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            SIA</v-btn
                          >
                          <v-btn
                            class="mt-2"
                            block
                            v-else
                            small
                            color="#eee"
                            light
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            SIA</v-btn
                          >
                        </template>

                        <v-card>
                          <v-card-title class="headline" primary-title>
                            SIA Settings
                          </v-card-title>

                          <v-card-text>
                            <v-text-field
                              v-model="group.settings.sia_minutes"
                              label="Notify if unavailable for"
                              suffix="minutes"
                            ></v-text-field>
                            <v-text-field
                              v-model="group.settings.sia_renotify"
                              label="Notify again every"
                              suffix="minutes"
                            ></v-text-field>
                            <v-checkbox
                              hide-details
                              dense
                              v-model="group.settings.sia_resolved"
                              color="primary"
                              label="Notify when resolved"
                            ></v-checkbox>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="pa-2">
                      <v-dialog width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-0"
                            block
                            v-if="group.settings.crl_minutes"
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            CRL</v-btn
                          >
                          <v-btn
                            block
                            v-else
                            small
                            color="#eee"
                            light
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            CRL</v-btn
                          >
                        </template>

                        <v-card>
                          <v-card-title class="headline" primary-title>
                            CRL Settings
                          </v-card-title>

                          <v-card-text>
                            <v-card>
                              <v-card-text>
                                <strong>Availability</strong>
                                <v-text-field
                                  v-model="group.settings.crl_minutes"
                                  label="Notify if unavailable for"
                                  suffix="minutes"
                                ></v-text-field>
                                <v-text-field
                                  v-model="group.settings.crl_renotify"
                                  label="Notify again every"
                                  suffix="minutes"
                                ></v-text-field>
                                <v-checkbox
                                  hide-details
                                  dense
                                  v-model="group.settings.crl_resolved"
                                  color="primary"
                                  label="Notify when resolved"
                                ></v-checkbox>
                              </v-card-text>
                            </v-card>
                            <v-card class="mt-2">
                              <v-card-text>
                                <strong>Expiration</strong>
                                <v-checkbox
                                  hide-details
                                  dense
                                  v-model="group.settings.crl_expired"
                                  color="primary"
                                  label="Notify at expiration"
                                ></v-checkbox>
                                <v-text-field
                                  v-model="group.settings.crl_percent_life"
                                  label="Notify at"
                                  suffix="percent life remaining"
                                ></v-text-field>
                                <v-text-field
                                  v-model="
                                    group.settings.crl_hours_to_expiration
                                  "
                                  label="Notify at"
                                  suffix="hours to expiration"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                        </v-card>
                      </v-dialog>

                      <v-dialog width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-2"
                            block
                            v-if="group.settings.ocsp_minutes"
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            OCSP</v-btn
                          >
                          <v-btn
                            class="mt-2"
                            block
                            v-else
                            small
                            color="#eee"
                            light
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon small class="pr-2">mdi-wrench</v-icon>
                            OCSP</v-btn
                          >
                        </template>

                        <v-card>
                          <v-card-title class="headline" primary-title>
                            OCSP Settings
                          </v-card-title>

                          <v-card-text>
                            <v-card>
                              <v-card-text>
                                <strong>Availability</strong>
                                <v-text-field
                                  v-model="group.settings.ocsp_minutes"
                                  label="Notify if unavailable for"
                                  suffix="minutes"
                                ></v-text-field>
                                <v-text-field
                                  v-model="group.settings.ocsp_renotify"
                                  label="Notify again every"
                                  suffix="minutes"
                                ></v-text-field>
                                <v-checkbox
                                  hide-details
                                  dense
                                  v-model="group.settings.ocsp_resolved"
                                  color="primary"
                                  label="Notify when resolved"
                                ></v-checkbox>
                              </v-card-text>
                            </v-card>
                            <v-card class="mt-2">
                              <v-card-text>
                                <strong>Expiration</strong>
                                <v-checkbox
                                  hide-details
                                  dense
                                  v-model="group.settings.ocsp_expired"
                                  color="primary"
                                  label="Notify at expiration"
                                ></v-checkbox>
                                <v-text-field
                                  v-model="group.settings.ocsp_percent_life"
                                  label="Notify at"
                                  suffix="percent life remaining"
                                ></v-text-field>
                                <v-text-field
                                  v-model="
                                    group.settings.ocsp_hours_to_expiration
                                  "
                                  label="Notify at"
                                  suffix="hours to expiration"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>

            <v-list-group :value="false" v-show="group.group.group_type === 1">
              <template v-slot:activator>
                <v-list-item-avatar
                  ><v-icon color="green"
                    >mdi-bell-ring</v-icon
                  ></v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title>Issued CAs</v-list-item-title>
                  <v-list-item-subtitle
                    >These settings apply to CAs issued by monitored
                    CAs</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                style="padding-left:50px;border-left:1px solid #ddd;margin-left:35px;"
              >
                <v-row>
                  <v-col cols="12" md="6"
                    ><v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.new_cas"
                      color="primary"
                      label="New"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.renewed_cas"
                      color="primary"
                      label="Renewed"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.rekeyed_cas"
                      color="primary"
                      label="Rekeyed"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.ca_revoked"
                      color="primary"
                      label="Revoked"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      dense
                      v-model="group.settings.ca_unrevoked"
                      color="primary"
                      label="Unrevoked"
                    ></v-checkbox>
                    <v-dialog width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          block
                          v-if="certExpiration(group.settings)"
                          small
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon small class="pr-2">mdi-wrench</v-icon>
                          Expiration</v-btn
                        >
                        <v-btn
                          block
                          v-else
                          small
                          color="#eee"
                          light
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon small class="pr-2">mdi-wrench</v-icon>
                          Expiration</v-btn
                        >
                      </template>

                      <v-card>
                        <v-card-title class="headline" primary-title>
                          Certificate Expiration Settings
                        </v-card-title>

                        <v-card-text>
                          <v-alert type="info" text>
                            You will receive expiration alerts only if a
                            certificate is still hosted <strong>or</strong> it's
                            the newest known version.
                          </v-alert>
                          <v-checkbox
                            hide-details
                            dense
                            v-model="group.settings.issued_expired"
                            color="primary"
                            label="Notify at expiration"
                          ></v-checkbox>
                          <v-text-field
                            v-model="group.settings.issued_percent_life"
                            label="Notify at"
                            suffix="percent life remaining"
                          ></v-text-field>
                          <v-text-field
                            v-model="group.settings.issued_hours_to_expiration"
                            label="Notify at"
                            suffix="hours to expiration"
                          ></v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <template v-else>
      <v-card-title
        @click.stop="expanded = !expanded"
        :style="`border-left: 25px solid ${group.settings.color};`"
      >
        <v-text-field
          hide-details
          dense
          v-model="group.group.name"
          outlined
          class="pl-0"
          v-if="editing_title"
          solo
        >
          <template v-slot:prepend class="pa-0 ma-0">
            <v-card
              :style="
                `width:40px;height:40px;background-color:${group.settings.color};`
              "
              elevation="4"
              class="pa-0 ma-0 mt-n2"
            ></v-card>
          </template>
          <template v-slot:append class="pa-0 ma-0">
            <v-btn
              small
              color="secondary darken-1"
              class="ma-1 pa-1"
              @click="editing_title = false"
              >cancel</v-btn
            ><v-btn
              small
              color="success darken-1"
              class="ma-1 pa-1"
              @click="editing_title = false"
              >Save</v-btn
            >
          </template>
        </v-text-field>
        <template v-else>
          <div style="width:100%;font-weight:bold;font-size:1.15em;">
            {{ group.group.name }}
          </div>
          <span
            v-if="group.group.group_type === 3"
            style="font-size:.70em;margin-top:-13px;color:#666"
            >Portal Application</span
          >
          <span
            v-else-if="group.group.group_type === 2"
            style="font-size:.70em;margin-top:-13px;color:#666"
          >
            Path Validation Group
          </span>
          <span
            v-else-if="group.group.group_type === 1"
            style="font-size:.70em;margin-top:-13px;color:#666"
          >
            Certification Authority Group
          </span>
        </template>
      </v-card-title>
      <v-card-text
        v-show="do_i_show"
        :style="`border-left: 25px solid ${group.settings.color};`"
        class="ma-0 mt-n1"
      >
        <v-btn small block color="secondary" class=" ma-0" @click="edit_group"
          ><v-icon class="mr-2">mdi-bell</v-icon> Notification Settings</v-btn
        >

        <template v-if="group.group.group_type === 1">
          <div v-for="test in group.group.ca_rules" :key="test.id">
            <v-divider class="ma-1"></v-divider>
            <CaRule
              :group="group.group"
              :rule="test"
              @delete-ca-rule="delete_ca_rule"
              :key="test.id"
            ></CaRule>
          </div>
          <v-divider class="ma-1"></v-divider>
          <v-row>
            <v-col cols="12" sm="6" class="ma-0 pa-0">
              <v-btn small block class="success"
                ><v-icon>mdi-plus</v-icon> Add Certificates</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import CaRule from "./groups/rules/CaRule";
import PathRule from "./groups/rules/PathRule";
import GroupSettings from "./groups/GroupSettings";
import NewPathRule from "./groups/rules/NewPathRule";

export default {
  name: "MonitoringGroupTile",
  props: ["group"],
  data: function() {
    return {
      expanded: false,
      editing_title: false,
      color_dialog: false,
      color_picked: null,
      color_group: null,
      deleting: false,
      delete_dialog: false,
      new_rule_dialog: false
    };
  },
  computed: {
    do_i_show: function() {
      return this.$vuetify.breakpoint.smAndUp || this.expanded;
    }
  },
  components: {
    NewPathRule,
    CaRule,
    PathRule,
    GroupSettings
  },
  methods: {
    edit_group: function() {
      this.$store.dispatch("edit_monitoring_group", this.group);
    },
    certExpiration: function(group) {
      return (
        group.ca_expired ||
        group.ca_percent_life ||
        group.ca_hours_to_expiration
      );
    },
    crlExpiration: function(group) {
      return (
        group.crl_expired ||
        group.crl_percent_life ||
        group.crl_hours_to_expiration
      );
    },
    ocspExpiration: function(group) {
      return (
        group.ocsp_expired ||
        group.ocsp_percent_life ||
        group.ocsp_hours_to_expiration
      );
    }
  }
};
</script>

<style scoped></style>
