<template>
  <v-row class="pa-2">
    <v-col cols="12" class="ma-0">
      <MonitoringGroupTile
        v-for="group in monitored_groups"
        :group="group"
        :key="group.id"
      />
    </v-col>
  </v-row>
</template>

<script>
import MonitoringGroupTile from "../components/MonitoringGroupTile";
export default {
  name: "MonitoringGroups",
  components: { MonitoringGroupTile },
  data: function() {
    return {
      expanded: false
    };
  },
  computed: {
    do_i_show: function() {
      return this.$vuetify.breakpoint.smAndUp || this.expanded;
    },
    monitored_groups: function() {
      return this.$store.getters.monitored_groups;
    }
  }
};
</script>

<style scoped></style>
