<template>
  <v-progress-circular
    v-if="saving"
    indeterminate
    color="secondary"
    style="float:left;"
    class="mr-2 mt-1"
  ></v-progress-circular>

  <div
    v-else-if="error"
    class="mr-2 pt-2"
    style="float:left;text-align:center;"
  >
    <v-icon color="red">mdi-cancel</v-icon>
  </div>
  <div v-else></div>
</template>

<script>
import UserService from "../../store/UserService";

export default {
  name: "GroupSettings",
  props: ["settings"],
  data: function() {
    return {
      saving: false,
      saved: false,
      error: false,
      group_error: null,
      old_settings: [],
      delayTimer: null
    };
  },
  created: function() {
    this.old_settings = Object.assign({}, this.settings);
  },
  methods: {
    updateGroup: function() {
      let self = this;
      self.group_error = null;
      self.saving = true;
      self.error = false;
      self.saved = false;
      for (var key in self.settings) {
        if ("" === self.settings[key]) self.settings[key] = null;
      }
      UserService.putLocalApi(
        "monitoring-group-settings/" + self.settings.id + "/",
        self.settings
      )
        .then(function() {
          self.$store.dispatch("loadUserSettings", true);
          self.saving = false;
          self.saved = true;
        })
        .catch(function(error) {
          console.log(error);
          self.error = true;
          self.saving = false;
          self.group_error = "Settings failed to save! Please try again.";
        });
      this.old_settings = Object.assign({}, this.settings);
    }
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        let self = this;
        if (this.settings.id === this.old_settings.id) {
          if (
            JSON.stringify(this.settings) !== JSON.stringify(this.old_settings)
          ) {
            clearTimeout(self.delayTimer);
            self.delayTimer = setTimeout(function() {
              // Do the ajax stuff
              self.updateGroup();
            }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
          }
        } else {
          this.old_settings = Object.assign({}, this.settings);
        }
      }
    }
  }
};
</script>

<style scoped></style>
